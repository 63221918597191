<template>
  <button
    class="border-0 mx-2 bg-transparent d-flex align-items-center"
    @click="changeLanguageHandler"
  >
    <b-img-lazy
      :src="displayedLocale.img"
      height="14px"
      width="22px"
      :alt="displayedLocale.locale"
    />
    <span
      class="ml-50 text-body"
      :class="displayedLocale.locale === 'ar' ? 'text-arabic' : ''"
    >
      {{ displayedLocale.name }}
    </span>
  </button>
</template>

<script>
import { BImgLazy } from "bootstrap-vue";
import i18n from "@/libs/i18n/index";

export default {
  components: {
    BImgLazy,
  },
  data() {
    return {
      locales: {
        ar: {
          locale: "ar",
          img: require("@/assets/images/flags/ar.png"),
          name: "اللغة العربية",
        },
        en: {
          locale: "en",
          img: require("@/assets/images/flags/en.png"),
          name: "English",
        },
      },
    };
  },
  computed: {
    displayedLocale() {
      return this.locales[i18n.locale === "ar" ? "en" : "ar"];
    },
    currentI18nLocale() {
      return i18n.locale;
    },
    current$I18nLocale() {
      return this.$i18n.locale;
    },
    localstorage() {
      return window.localStorage.getItem("language");
    },
  },
  mounted() {
    const defaultLang = window.localStorage.getItem("language");
    if (defaultLang) this.setLanguage(defaultLang);
  },
  methods: {
    changeLanguageHandler() {
      const currentLang = window.localStorage.getItem("language");
      const targetLang = currentLang === "ar" ? "en" : "ar";
      this.setLanguage(targetLang);
      // Todo : remove this reload
      setTimeout(() => {
        window.location.reload();
      }, 0);
    },
    setLanguage(lang) {
      this.$i18n.locale = lang;
      i18n.locale = lang;
      window.localStorage.setItem("language", lang);
      this.$store.commit("appConfig/TOGGLE_RTL", lang);
    },
  },
};
</script>

<style>
</style>
